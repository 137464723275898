import React from "react";
import { graphql } from "gatsby";

const MarkdownBlogPostTemplate = ({ data }) => {
  const { post } = data;
  return (
    <pre style={{ whiteSpace: "pre-wrap", fontFamily: "inherit" }}>
      {post.markdownSummary
        ? post.markdownSummary
        : "No markdown summary provided."}
    </pre>
  );
};

export const query = graphql`
  query MarkdownBlogPostQuery($id: String!) {
    post: datoCmsBlogPost(id: { eq: $id }) {
      markdownSummary
    }
  }
`;

export default MarkdownBlogPostTemplate;
